import { useAxios } from '@/hooks/useAxios'
import { convertToFormData } from '@/utils'

const request = useAxios()

// 客户列表
export const list_enterprise = (data) => {
    return request.post({
        url: '/ent/enterprise/list_enterprise',
        data,
    })
}

// 企业详情
export const get_enterprise = (data) => {
    return request.post({
        url: '/ent/enterprise/get_enterprise',
        data,
    })
}

// 已选客户经理
export const selected_manager = (data) => {
    return request.post({
        url: '/ent/enterprise/selected_manager',
        data,
    })
}

// 添加标签
export const add_tag = (data) => {
    return request.post({
        url: '/ent/enterprise/add_tag',
        data,
    })
}

// 黑名单状态
export const black_list_status = (data) => {
    return request.post({
        url: '/ent/enterprise/black_list_status',
        data,
    })
}

// 可选客户经理
export const selectable_manager = (data) => {
    return request.post({
        url: '/ent/enterprise/selectable_manager',
        data,
    })
}

// 修改客户经理
export const change_manager = (data) => {
    return request.post({
        url: '/ent/enterprise/change_manager',
        data,
    })
}

// 审核不通过
export const un_approved = (data) => {
    return request.post({
        url: '/ent/enterprise/un_approved',
        data,
    })
}

// 审核通过
export const approved = (data) => {
    return request.post({
        url: '/ent/enterprise/approved',
        data,
    })
}

// 企业信息操作记录
export const list_ent_operate = (data) => {
    return request.post({
        url: '/ent/enterprise/list_ent_operate',
        data,
    })
}

// 无票免征

// 获取无票免征列表
export const getTicketList = (data) => {
    return request.post({
        url: '/tax/no_ticket/get_ticket_list',
        data,
    })
}

// 暂存/提交无票免征业务
export const submitTicketBusiness = (data) => {
    return request.post({
        url: '/tax/no_ticket/submit_ticket_business',
        data,
    })
}

// 获取业务详情
export const getTicketDetail = (data) => {
    return request.post({
        url: '/tax/no_ticket/get_ticket_detail',
        data,
    })
}

// 获取无票免征商品表体信息
export const getNoticketGoods = (data) => {
    return request.post({
        url: '/tax/no_ticket/get_noticket_goods',
        data,
    })
}

// 获取可选择的无票免征商品
export const getElsitGoods = (data) => {
    return request.post({
        url: '/tax/no_ticket/get_elsit_goods',
        data,
    })
}

// 添加/删除商品
export const operateTicketGood = (data) => {
    return request.post({
        url: '/tax/no_ticket/operate_ticket_good',
        data,
    })
}

// 导出无票免税
export const exportNoticket = (data) => {
    return request.post({
        url: '/tax/no_ticket/export_noticket',
        responseType: 'blob',
        data,
    })
}

//导入
export const importNoticketGoods = (id,file) => {
    return request.post({
      url: '/tax/no_ticket/import_noticket_goods',
      headersType: 'multipart/form-data',
      data: convertToFormData({
        nt_id: id,
        goods: file,
      }),
    })
  }