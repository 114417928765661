<template>
  <div class="check">
    <div class="flex">
      <div class="flexBox">
        <div class="flexTitle" style="width: 200px">自定义业务名称</div>
        <el-input v-model="search_param.business_name"></el-input>
      </div>
      <div class="flexBox">
        <div class="flexTitle">申请编号</div>
        <el-input v-model="search_param.ticket_no"></el-input>
      </div>
      <div class="flexBox">
        <div class="flexTitle">提交日期</div>
        <el-date-picker
          v-model="search_param.submit_time"
          type="datetimerange"
          range-separator="~"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          style="width: 100%"
        />
      </div>
      <div class="flexBox" style="margin-right: 30px">
        <div class="flexTitle">状态</div>
        <el-select
          v-model="search_param.nt_status"
          placeholder="全部状态"
          style="width: 100%"
          clearable
        >
          <el-option key="1" label="暂存" :value="1" />
          <el-option key="2" label="提交" :value="2" />
        </el-select>
      </div>
    </div>
    <div class="flex" style="margin: 15px 0">
      <div class="flexBox">
        <el-button
          v-debounce
          type="primary"
          style="margin-left: 5px"
          @click="getList"
          >查询</el-button
        >
        <el-button v-debounce @click="reset">重置</el-button>
        <el-button
          v-debounce
          type="primary"
          @click="
            () => {
              initRow();
              showDrawer = true;
            }
          "
          >新增</el-button
        >
        <el-button v-debounce @click="exportFile">导出</el-button>
      </div>
      <div class="flexBox" style="margin-right: 30px">
        <el-button
          type="primary"
          style="margin-left: auto"
          round
          @click="tableShow = false"
        >
          点击收起<el-icon class="el-icon--right"><ArrowUpBold /></el-icon>
        </el-button>
        <el-button type="primary" round @click="tableShow = true">
          点击展开<el-icon class="el-icon--right"><ArrowDownBold /></el-icon>
        </el-button>
      </div>
    </div>
    <div v-show="tableShow">
      <el-table
        :data="tableData"
        :border="1"
        :header-cell-style="{ 'background-color': '#f6f6f6' }"
        :cell-style="{ fontSize: '12px' }"
        style="width: 100%"
        height="460"
        @header-dragend="headerDragend"
        @selection-change="handleSelectionChange2"
        @row-click="rowClick"
      >
        <el-table-column type="selection" label="序号" width="55" />
        <el-table-column label="状态" min-width="80px" show-overflow-tooltip>
          <template v-slot:default="scope">
            <div>{{ scope.row.nt_status || "暂无" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="所属期" min-width="100px" show-overflow-tooltip>
          <template v-slot:default="scope">
            <div>{{ scope.row.period || "暂无" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="申请编号"
          min-width="120px"
          show-overflow-tooltip
        >
          <template v-slot:default="scope">
            <div>{{ scope.row.ticket_no || "暂无" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="登记提交日期"
          min-width="120px"
          show-overflow-tooltip
        >
          <template v-slot:default="scope">
            <div>{{ scope.row.submit_time || "暂无" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="电商企业海关代码"
          min-width="120px"
          show-overflow-tooltip
        >
          <template v-slot:default="scope">
            <div>{{ scope.row.ebc_code || "暂无" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="电商企业名称"
          min-width="120px"
          show-overflow-tooltip
        >
          <template v-slot:default="scope">
            <div>{{ scope.row.ebc_name || "暂无" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="电商企业统一社会信用代码"
          min-width="160px"
          show-overflow-tooltip
        >
          <template v-slot:default="scope">
            <div>{{ scope.row.ebc_social_credit_no || "暂无" }}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="自定义业务名称"
          min-width="120px"
          show-overflow-tooltip
        >
          <template v-slot:default="scope">
            <div>{{ scope.row.business_name || "暂无" }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="demo-pagination-block">
        <el-pagination
          v-model:current-page="currentPage"
          :page-sizes="[10, 30, 50, 100, 500]"
          v-model:page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        />
      </div>
    </div>
    <!-- 抽屉详情 -->
    <el-drawer
      v-model="showDrawer"
      :with-header="false"
      direction="btt"
      size="100%"
      @close="activeName = 'first'"
    >
      <div>
        <div style="margin-bottom: 10px">
          <span style="font-weight: 700; font-size: 15px; cursor: pointer">
            <span @click="showDrawer = false"
              ><el-icon style="position: relative; top: 2px"
                ><ArrowLeftBold /></el-icon
              >返回</span
            >
            <span style="margin: 0 10px">|</span>
            <span>新增无票免税</span>
          </span>
        </div>
        <el-divider />
        <el-tabs
          v-model="activeName"
          class="demo-tabs"
          @tab-click="handleClick"
          style="margin-top: -15px"
        >
          <el-tab-pane label="表头" name="first">
            <div class="detail">
              <div>
                <el-button type="primary" @click="submitNt(1)">暂存</el-button>
                <el-button type="primary" @click="submitNt(2)">提交</el-button>
              </div>
              <div class="flex" style="margin: 20px 0 10px 0">
                <div class="inputStyle">
                  <div class="span1" style="width: 120px">电商企业名称</div>
                  <div class="span2">
                    <el-input v-model="rowDetail.ebc_name" disabled clearable>
                    </el-input>
                  </div>
                </div>
                <div class="inputStyle">
                  <div class="span1">电商企业海关代码</div>
                  <div class="span2">
                    <el-input v-model="rowDetail.ebc_code" disabled clearable>
                    </el-input>
                  </div>
                </div>
                <div class="inputStyle">
                  <div class="span1">电商企业统一社会信用代码</div>
                  <div class="span2">
                    <el-input
                      v-model="rowDetail.ebc_social_credit_no"
                      disabled
                      clearable
                    >
                    </el-input>
                  </div>
                </div>
              </div>
              <div class="flex" style="margin: 10px 0">
                <div class="inputStyle">
                  <div class="span1" style="width: 120px">申请编号</div>
                  <div class="span2">
                    <el-input v-model="rowDetail.ticket_no" disabled clearable>
                    </el-input>
                  </div>
                </div>
                <div class="inputStyle">
                  <div class="span1">所属期限</div>
                  <div class="span2">
                    <el-date-picker
                      v-model="rowDetail.period"
                      type="month"
                      value-format="YYYY-MM"
                      style="width: 100%"
                    />
                  </div>
                </div>
                <div class="inputStyle"></div>
              </div>
              <div class="flex" style="margin: 10px 0">
                <div class="inputStyle">
                  <div class="span1" style="width: 120px">状态</div>
                  <div class="span2">
                    <el-input v-model="rowDetail.nt_status" disabled clearable>
                    </el-input>
                  </div>
                </div>
                <div class="inputStyle">
                  <div class="span1">登记提交日期</div>
                  <div class="span2">
                    <el-input
                      v-model="rowDetail.submit_time"
                      disabled
                      clearable
                    >
                    </el-input>
                  </div>
                </div>
                <div class="inputStyle">
                  <div class="span1">自定义业务名称</div>
                  <div class="span2">
                    <el-input v-model="rowDetail.business_name" clearable>
                    </el-input>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="表体" name="second" :disabled="!rowDetail.nt_id">
            <div>
              <div style="margin-bottom: 10px">
                <el-button type="primary" @click="showDrawer2 = true"
                  >快速获取结关清单商品</el-button
                >
                <el-button type="primary" @click="showDialog = true"
                  >导入</el-button
                >
                <el-button type="primary" @click="operateTicket(1)"
                  >移出</el-button
                >
              </div>
              <el-table
                :data="tableData2"
                style="width: 100%; height: 450px"
                :border="1"
                @sort-change="handleSortChange"
                @selection-change="handleSelectionChange"
                :header-cell-class-name="handleHeaderClass"
              >
                <el-table-column type="selection" width="55" />
                <el-table-column min-width="100px" show-overflow-tooltip>
                  <template v-slot:default="scope">
                    <div>{{ scope.row.invt_no || "暂无" }}</div>
                  </template>
                  <template v-slot:header>
                    <div style="display: flex; align-items: center">
                      <div>清单编号</div>
                      <div class="tableHeader">
                        <el-popover
                          placement="bottom-end"
                          :width="300"
                          trigger="click"
                          @hide="clearValue"
                        >
                          <template #reference>
                            <el-icon color="#1890ff" size="15"
                              ><Search
                            /></el-icon>
                          </template>
                          <div style="display: flex">
                            <el-input
                              v-model="search_param2.invt_no"
                              clearable
                            ></el-input>
                            <el-button
                              v-debounce
                              type="primary"
                              @click="getList2"
                              style="margin-left: 10px"
                              >搜索</el-button
                            >
                          </div>
                        </el-popover>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="项号"
                  min-width="100px"
                  show-overflow-tooltip
                >
                  <template v-slot:default="scope">
                    <div>{{ scope.row.order_number || "暂无" }}</div>
                  </template>
                </el-table-column>
                <el-table-column min-width="100px" show-overflow-tooltip>
                  <template v-slot:default="scope">
                    <div>{{ scope.row.gcode || "暂无" }}</div>
                  </template>
                  <template v-slot:header>
                    <div style="display: flex; align-items: center">
                      <div>HS编码</div>
                      <div class="tableHeader">
                        <el-popover
                          placement="bottom-end"
                          :width="300"
                          trigger="click"
                          @hide="clearValue"
                        >
                          <template #reference>
                            <el-icon color="#1890ff" size="15"
                              ><Search
                            /></el-icon>
                          </template>
                          <div style="display: flex">
                            <el-input
                              v-model="search_param2.gcode"
                              clearable
                            ></el-input>
                            <el-button
                              v-debounce
                              type="primary"
                              @click="getList2"
                              style="margin-left: 10px"
                              >搜索</el-button
                            >
                          </div>
                        </el-popover>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="出口日期"
                  min-width="100px"
                  show-overflow-tooltip
                >
                  <template v-slot:default="scope">
                    <div>{{ scope.row.last_receipt_time || "暂无" }}</div>
                  </template>
                </el-table-column>
                <el-table-column min-width="100px" show-overflow-tooltip>
                  <template v-slot:default="scope">
                    <div>{{ scope.row.item_name || "暂无" }}</div>
                  </template>
                  <template v-slot:header>
                    <div style="display: flex; align-items: center">
                      <div>货物名称</div>
                      <div class="tableHeader">
                        <el-popover
                          placement="bottom-end"
                          :width="300"
                          trigger="click"
                          @hide="clearValue"
                        >
                          <template #reference>
                            <el-icon color="#1890ff" size="15"
                              ><Search
                            /></el-icon>
                          </template>
                          <div style="display: flex">
                            <el-input
                              v-model="search_param2.item_name"
                              clearable
                            ></el-input>
                            <el-button
                              v-debounce
                              type="primary"
                              @click="getList2"
                              style="margin-left: 10px"
                              >搜索</el-button
                            >
                          </div>
                        </el-popover>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="计量单位"
                  min-width="100px"
                  show-overflow-tooltip
                >
                  <template v-slot:default="scope">
                    <el-select
                      v-model="scope.row.unit"
                      disabled
                      clearable
                      filterable
                    >
                      <el-option
                        v-for="item in UnitOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  label="单价"
                  min-width="100px"
                  show-overflow-tooltip
                >
                  <template v-slot:default="scope">
                    <div>{{ scope.row.price || "暂无" }}</div>
                  </template>
                </el-table-column>
                <el-table-column min-width="100px" show-overflow-tooltip>
                  <template v-slot:default="scope">
                    <el-select
                      v-model="scope.row.currency"
                      disabled
                      clearable
                      filterable
                    >
                      <el-option
                        v-for="item in CurrencyCodeOption"
                        :key="item.currency_code"
                        :label="item.currency_Chinese_name"
                        :value="item.currency_code"
                      />
                    </el-select>
                  </template>
                  <template v-slot:header>
                    <div style="display: flex; align-items: center">
                      <div>币制</div>
                      <div class="tableHeader">
                        <el-popover
                          placement="bottom-end"
                          :width="300"
                          trigger="click"
                          @hide="clearValue"
                        >
                          <template #reference>
                            <el-icon color="#1890ff" size="15"
                              ><Search
                            /></el-icon>
                          </template>
                          <div style="display: flex">
                            <el-input
                              v-model="search_param2.currency"
                              clearable
                            ></el-input>
                            <el-button
                              v-debounce
                              type="primary"
                              @click="getList2"
                              style="margin-left: 10px"
                              >搜索</el-button
                            >
                          </div>
                        </el-popover>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="数量"
                  min-width="100px"
                  prop="order_by_qty"
                  sortable="custom"
                  show-overflow-tooltip
                >
                  <template v-slot:default="scope">
                    <div>{{ scope.row.qty || "暂无" }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="金额"
                  min-width="100px"
                  show-overflow-tooltip
                >
                  <template v-slot:default="scope">
                    <div>{{ scope.row.total_price || "暂无" }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="demo-pagination-block">
              <el-pagination
                v-model:current-page="currentPage2"
                :page-sizes="[10, 30, 50, 100, 500]"
                v-model:page-size="pageSize2"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount2"
                @current-change="handleCurrentChange2"
                @size-change="handleSizeChange2"
              />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
    <!-- 第二个 -->
    <el-drawer
      v-model="showDrawer2"
      :with-header="false"
      direction="btt"
      size="100%"
    >
      <div>
        <div style="margin-bottom: 10px">
          <span style="font-weight: 700; font-size: 15px; cursor: pointer">
            <span @click="showDrawer2 = false"
              ><el-icon style="position: relative; top: 2px"
                ><ArrowLeftBold /></el-icon
              >返回</span
            >
            <span style="margin: 0 10px">|</span>
            <span>选择数据</span>
          </span>
        </div>
        <el-divider />
        <div class="flex">
          <div class="flexBox">
            <div class="flexTitle">清单编号</div>
            <el-input v-model="search_param3.invt_no"></el-input>
          </div>
          <div class="flexBox">
            <div class="flexTitle">提运单号</div>
            <el-input v-model="search_param3.bill_no"></el-input>
          </div>
          <div class="flexBox">
            <div class="flexTitle">出口日期</div>
            <el-date-picker
              v-model="search_param3.export_time"
              type="datetimerange"
              range-separator="~"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              style="width: 100%"
            />
          </div>
          <div class="flexBox" style="margin-right: 30px">
            <el-button v-debounce type="primary" @click="getList3"
              >查询</el-button
            >
            <el-button v-debounce @click="reset">重置</el-button>
            <el-button v-debounce type="primary" @click="operateTicket(2)"
              >应用数据</el-button
            >
            <el-button v-debounce type="primary" @click="operateTicket(3)"
              >应用并覆盖</el-button
            >
          </div>
        </div>
        <el-table
          :data="tableData3"
          style="width: 100%; height: 450px"
          :border="1"
          @sort-change="handleSortChange2"
          @selection-change="handleSelectionChange1"
          :header-cell-class-name="handleHeaderClass2"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column min-width="100px" show-overflow-tooltip>
            <template v-slot:default="scope">
              <div>{{ scope.row.invt_no || "暂无" }}</div>
            </template>
            <template v-slot:header>
              <div style="display: flex; align-items: center">
                <div>清单编号</div>
                <div class="tableHeader">
                  <el-popover
                    placement="bottom-end"
                    :width="300"
                    trigger="click"
                    @hide="clearValue2"
                  >
                    <template #reference>
                      <el-icon color="#1890ff" size="15"><Search /></el-icon>
                    </template>
                    <div style="display: flex">
                      <el-input
                        v-model="search_param3.invt_no"
                        clearable
                      ></el-input>
                      <el-button
                        v-debounce
                        type="primary"
                        @click="getList3"
                        style="margin-left: 10px"
                        >搜索</el-button
                      >
                    </div>
                  </el-popover>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="项号" min-width="100px" show-overflow-tooltip>
            <template v-slot:default="scope">
              <div>{{ scope.row.order_number || "暂无" }}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="100px" show-overflow-tooltip>
            <template v-slot:default="scope">
              <div>{{ scope.row.gcode || "暂无" }}</div>
            </template>
            <template v-slot:header>
              <div style="display: flex; align-items: center">
                <div>HS编码</div>
                <div class="tableHeader">
                  <el-popover
                    placement="bottom-end"
                    :width="300"
                    trigger="click"
                    @hide="clearValue2"
                  >
                    <template #reference>
                      <el-icon color="#1890ff" size="15"><Search /></el-icon>
                    </template>
                    <div style="display: flex">
                      <el-input
                        v-model="search_param3.gcode"
                        clearable
                      ></el-input>
                      <el-button
                        v-debounce
                        type="primary"
                        @click="getList3"
                        style="margin-left: 10px"
                        >搜索</el-button
                      >
                    </div>
                  </el-popover>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="出口日期"
            min-width="100px"
            show-overflow-tooltip
          >
            <template v-slot:default="scope">
              <div>{{ scope.row.last_receipt_time || "暂无" }}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="100px" show-overflow-tooltip>
            <template v-slot:default="scope">
              <div>{{ scope.row.item_name || "暂无" }}</div>
            </template>
            <template v-slot:header>
              <div style="display: flex; align-items: center">
                <div>货物名称</div>
                <div class="tableHeader">
                  <el-popover
                    placement="bottom-end"
                    :width="300"
                    trigger="click"
                    @hide="clearValue2"
                  >
                    <template #reference>
                      <el-icon color="#1890ff" size="15"><Search /></el-icon>
                    </template>
                    <div style="display: flex">
                      <el-input
                        v-model="search_param3.item_name"
                        clearable
                      ></el-input>
                      <el-button
                        v-debounce
                        type="primary"
                        @click="getList3"
                        style="margin-left: 10px"
                        >搜索</el-button
                      >
                    </div>
                  </el-popover>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="计量单位"
            min-width="100px"
            show-overflow-tooltip
          >
            <template v-slot:default="scope">
              <div>{{ scope.row.unit || "暂无" }}</div>
            </template>
          </el-table-column>
          <el-table-column label="单价" min-width="100px" show-overflow-tooltip>
            <template v-slot:default="scope">
              <div>{{ scope.row.price || "暂无" }}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="100px" show-overflow-tooltip>
            <template v-slot:default="scope">
              <div>{{ scope.row.currency || "暂无" }}</div>
            </template>
            <template v-slot:header>
              <div style="display: flex; align-items: center">
                <div>币制</div>
                <div class="tableHeader">
                  <el-popover
                    placement="bottom-end"
                    :width="300"
                    trigger="click"
                    @hide="clearValue2"
                  >
                    <template #reference>
                      <el-icon color="#1890ff" size="15"><Search /></el-icon>
                    </template>
                    <div style="display: flex">
                      <el-input
                        v-model="search_param3.currency"
                        clearable
                      ></el-input>
                      <el-button
                        v-debounce
                        type="primary"
                        @click="getList3"
                        style="margin-left: 10px"
                        >搜索</el-button
                      >
                    </div>
                  </el-popover>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="数量"
            min-width="100px"
            prop="order_by_qty"
            sortable="custom"
            show-overflow-tooltip
          >
            <template v-slot:default="scope">
              <div>{{ scope.row.qty || "暂无" }}</div>
            </template>
          </el-table-column>
          <el-table-column label="金额" min-width="100px" show-overflow-tooltip>
            <template v-slot:default="scope">
              <div>{{ scope.row.total_price || "暂无" }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="demo-pagination-block">
        <el-pagination
          v-model:current-page="currentPage3"
          :page-sizes="[10, 30, 50, 100, 500]"
          v-model:page-size="pageSize3"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount3"
          @current-change="handleCurrentChange3"
          @size-change="handleSizeChange3"
        />
      </div>
    </el-drawer>
    <!-- 导入 -->
    <el-dialog
      v-model="showDialog"
      @close="closeUpload"
      title="上传文件"
      width="650px"
    >
      <div>
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <el-upload
            :limit="1"
            :auto-upload="false"
            :on-change="handleUpload"
            style="text-align: center"
            ref="uploadFileRef"
          >
            <el-button v-debounce type="primary">选择文件</el-button>
          </el-upload>
        </div>
        <div class="el-upload__tip">
          <a :href="tUrl" class="dowload">点击下载模板</a>
        </div>
        <div v-if="uploadList.length > 0">
          <ul>
            <li v-for="(file, index) in uploadList" :key="index">
              {{ file.name }}
            </li>
          </ul>
        </div>
      </div>
      <span class="dialog-footer">
        <el-button
          v-debounce
          @click="
            closeUpload();
            showDialog = false;
          "
          >取 消</el-button
        >
        <el-button v-debounce type="primary" @click="handleConfirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import {
  getTicketList,
  submitTicketBusiness,
  getTicketDetail,
  getNoticketGoods,
  getElsitGoods,
  operateTicketGood,
  exportNoticket,
  importNoticketGoods,
} from "@/api/import/noTicket.js";
import { getCurrency } from "@/api/public/index";
import { ElMessage } from "element-plus";
import { Search } from "@element-plus/icons-vue";
import {
  ArrowLeftBold,
  ArrowDownBold,
  ArrowUpBold,
} from "@element-plus/icons-vue";
import fileDownload from "js-file-download";
import { UnitOption } from "@/utils/options.js";

//查询参数对象
const search_param = reactive({
  period: "",
  nt_status: "",
  ticket_no: "",
  business_name: "",
  submit_time: [],
  page: 1,
  size: 10,
});

//查询参数对象
const search_param2 = reactive({
  invt_no: "",
  bill_no: "",
  item_name: "",
  gcode: "",
  order_by_qty: "",
  currency: "",
  export_time: [],
  page: 1,
  size: 10,
});

const search_param3 = reactive({
  invt_no: "",
  bill_no: "",
  item_name: "",
  gcode: "",
  order_by_qty: "",
  currency: "",
  export_time: [],
  page: 1,
  size: 10,
});

//隐藏搜索框
const clearValue = () => {
  Object.assign(search_param2, {
    invt_no: "",
    bill_no: "",
    item_name: "",
    gcode: "",
    order_by_qty: "",
    currency: "",
    export_time: [],
  });
};

//隐藏搜索框
const clearValue2 = () => {
  Object.assign(search_param3, {
    invt_no: "",
    bill_no: "",
    item_name: "",
    gcode: "",
    order_by_qty: "",
    currency: "",
    export_time: [],
  });
};

// 重置功能
const reset = () => {
  search_param.period = "";
  search_param.nt_status = "";
  search_param.ticket_no = "";
  search_param.business_name = "";
  search_param.submit_time = [];
};

const tableShow = ref(true);

const exportFile = async () => {
  if (!multipleSelection2.value.length) {
    return ElMessage({
      grouping: true,
      showClose: true,
      message: "请选择需要导出的数据",
      type: "error",
    });
  }
  if (!multipleSelection2.value.length > 10) {
    return ElMessage({
      grouping: true,
      showClose: true,
      message: "最多支持10条导出数据",
      type: "error",
    });
  }
  let res = await exportNoticket({
    nt_ids: multipleSelection2.value.map((item) => item.nt_id),
  });
  let filename = res.headers["content-disposition"];
  if (filename) {
    const index = filename.indexOf("filename=");
    if (index >= 0) filename = decodeURI(filename.substr(index + 9));
  }
  fileDownload(res.data, filename, "utf-8");
  ElMessage({
    grouping: true,
    showClose: true,
    message: "导出成功",
    type: "success",
  });
};

//分页逻辑
const currentPage = ref(1);
const pageSize = ref(10);
const totalCount = ref(0);
const handleCurrentChange = (val) => {
  currentPage.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};
const handleSizeChange = (val) => {
  pageSize.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};

// 表格数据
let tableData = ref([]);
let multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  multipleSelection.value = val;
};
let multipleSelection1 = ref([]);
const handleSelectionChange1 = (val) => {
  multipleSelection1.value = val;
};

let multipleSelection2 = ref([]);
const handleSelectionChange2 = (val) => {
  multipleSelection2.value = val;
};

//获得数据列表
const getList = ({
  cP = currentPage.value,
  size = pageSize.value,
  tips = false,
}) => {
  currentPage.value = cP;
  pageSize.value = size;
  getTicketList({
    period: search_param.period,
    nt_status: search_param.nt_status,
    ticket_no: search_param.ticket_no,
    business_name: search_param.business_name,
    submit_time: search_param.submit_time,
    page: cP,
    size: size,
  }).then((res) => {
    if (res.code == 200) {
      tableData.value = res.data.list;
      totalCount.value = res.data.count;
      if (!tips)
        ElMessage({
          grouping: true,
          showClose: true,
          message: res.msg,
          type: "success",
        });
    }
  });
};

const rowClick = async (item) => {
  rowDetail.value.nt_id = item.nt_id;
  await getDetail();
  showDrawer.value = true;
};

const rowDetail = ref({
  nt_id: "",
  nt_status: "",
  period: "",
  business_name: "",
});
const initRow = () => {
  Object.keys(rowDetail.value).map((item) => (rowDetail.value[item] = ""));
};
const submitNt = async (type) => {
  let res = await submitTicketBusiness({
    nt_id: rowDetail.value.nt_id || "",
    nt_status: type,
    period: rowDetail.value.period || "",
    business_name: rowDetail.value.business_name || "",
  });
  if (res.code == 200) {
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg,
      type: "success",
    });
    rowDetail.value.nt_id = res.data.nt_id;
    getList({ cP: currentPage.value, size: pageSize.value, tips: true });
    getDetail();
  }
};
// 获取详情
const getDetail = async () => {
  let res = await getTicketDetail({ nt_id: rowDetail.value?.nt_id || "" });
  if (res.code == 200) {
    rowDetail.value = res.data;
  }
};

//上传
const tUrl = process.env.VUE_APP_BASE_URL + "demo/无票免税表体导入模板.xlsx";
const showDialog = ref(false);
const uploadFileRef = ref();
const uploadList = ref([]);
const handleUpload = (fileList) => {
  uploadList.value = fileList;
};
// 关闭上传框
const closeUpload = () => {
  uploadFileRef.value.clearFiles();
};
const handleConfirm = async () => {
  await importNoticketGoods(rowDetail.value.nt_id, uploadList.value.raw).then(
    (res) => {
      if (res.code === 200) {
        ElMessage({
          grouping: true,
          showClose: true,
          message: res.msg || "导入成功",
          type: "success",
        });
        showDialog.value = false;
        getList2({ cP: currentPage2.value, size: pageSize2.value, tips: true });
      }
    }
  );
};

const CurrencyCodeOption = ref([]);
const getCurrencyOption = async () => {
  let res = await getCurrency();
  if (res.code == 200) CurrencyCodeOption.value = res.data;
};

const showDrawer = ref(false);
const activeName = ref("first");
const handleClick = (tab) => {
  if (tab.props.name == "second")
    getList2({ cP: currentPage2.value, size: pageSize2.value, tips: true });
};

//分页逻辑
const tableData2 = ref([]);
const currentPage2 = ref(1);
const pageSize2 = ref(10);
const totalCount2 = ref(0);
const handleCurrentChange2 = (val) => {
  currentPage2.value = val;
  getList2({
    cP: currentPage2.value,
    size: pageSize2.value,
    tips: true,
  });
};
const handleSizeChange2 = (val) => {
  pageSize2.value = val;
  getList2({
    cP: currentPage2.value,
    size: pageSize2.value,
    tips: true,
  });
};
// 获取
const getList2 = async ({
  cP = currentPage2.value,
  size = pageSize2.value,
  tips = false,
}) => {
  let res = await getNoticketGoods({
    nt_id: rowDetail.value.nt_id || "",
    invt_no: search_param2.invt_no || "",
    bill_no: search_param2.bill_no || "",
    item_name: search_param2.item_name || "",
    gcode: search_param2.gcode || "",
    order_by_qty: search_param2.order_by_qty || "",
    currency: search_param2.currency || "",
    export_time: search_param2.export_time || [],
    page: cP,
    size: size,
  });
  if (res.code == 200) {
    totalCount2.value = res.data.count;
    tableData2.value = res.data.list;
    if (!tips)
      ElMessage({
        grouping: true,
        showClose: true,
        message: res.msg,
        type: "success",
      });
  }
};

const showDrawer2 = ref(false);

//分页逻辑
const tableData3 = ref([]);
const currentPage3 = ref(1);
const pageSize3 = ref(10);
const totalCount3 = ref(0);
const handleCurrentChange3 = (val) => {
  currentPage3.value = val;
  getList3({
    cP: currentPage3.value,
    size: pageSize3.value,
    tips: true,
  });
};
const handleSizeChange3 = (val) => {
  pageSize3.value = val;
  getList3({
    cP: currentPage3.value,
    size: pageSize3.value,
    tips: true,
  });
};
// 获取
const getList3 = async ({
  cP = currentPage3.value,
  size = pageSize3.value,
  tips = false,
}) => {
  let res = await getElsitGoods({
    invt_no: search_param3.invt_no || "",
    bill_no: search_param3.bill_no || "",
    item_name: search_param3.item_name || "",
    gcode: search_param3.gcode || "",
    order_by_qty: search_param3.order_by_qty || "",
    currency: search_param3.currency || "",
    export_time: search_param3.export_time || [],
    page: cP,
    size: size,
  });
  if (res.code == 200) {
    totalCount3.value = res.data.count;
    tableData3.value = res.data.list;
    if (!tips)
      ElMessage({
        grouping: true,
        showClose: true,
        message: res.msg,
        type: "success",
      });
  }
};

const operateTicket = async (type) => {
  let sType = "";
  let sAll = "";
  let ids = [];
  if (type == 1) {
    if (!multipleSelection.value.length) {
      return ElMessage({
        grouping: true,
        showClose: true,
        message: "请选择需要操作的数据",
        type: "error",
      });
    }
    ids = multipleSelection.value.map((item) => item.eg_id);
    sType = 2;
    sAll = 0;
  } else if (type == 2) {
    if (!multipleSelection1.value.length) {
      return ElMessage({
        grouping: true,
        showClose: true,
        message: "请选择需要操作的数据",
        type: "error",
      });
    }
    ids = multipleSelection1.value.map((item) => item.eg_id);
    sType = 1;
    sAll = 0;
  } else {
    if (!multipleSelection1.value.length) {
      return ElMessage({
        grouping: true,
        showClose: true,
        message: "请选择需要操作的数据",
        type: "error",
      });
    }
    ids = multipleSelection1.value.map((item) => item.eg_id);
    sType = 1;
    sAll = 1;
  }
  let res = await operateTicketGood({
    nt_id: rowDetail.value.nt_id,
    type: sType,
    is_all: sAll,
    eg_ids: ids,
  });
  if (res.code == 200) {
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg,
      type: "success",
    });
    getList2({
      cP: currentPage2.value,
      size: pageSize2.value,
      tips: true,
    });
    showDrawer2.value = false;
    getList3({
      cP: currentPage3.value,
      size: pageSize3.value,
      tips: true,
    });
  }
};

// 拉伸表格
const headerDragend = (row, column, cell) => {
  if (row < cell.minWidth) cell.width = cell.minWidth;
};

//排序样式
const handleHeaderClass = ({ column }) => {
  const { property } = column;
  if (sortState[property]) {
    column.order = sortState[property];
  }
};
//初始状态
const sortState = {
  order_by_qty: null,
};
const handleSortChange = ({ prop, order }) => {
  // 复制当前的排序状态
  sortState[prop] = order;
  // 更新所有列的排序状态
  if (sortState.order_by_qty === "ascending") {
    search_param2.order_by_qty = 1;
  } else if (sortState.order_by_qty === "descending") {
    search_param2.order_by_qty = 2;
  } else {
    search_param2.order_by_qty = 0;
  }
  getList2({ cP: currentPage2.value, size: pageSize2.value, tips: true });
};

//排序样式
const handleHeaderClass2 = ({ column }) => {
  const { property } = column;
  if (sortState2[property]) {
    column.order = sortState2[property];
  }
};
//初始状态
const sortState2 = {
  order_by_qty: null,
};
const handleSortChange2 = ({ prop, order }) => {
  // 复制当前的排序状态
  sortState2[prop] = order;
  // 更新所有列的排序状态
  if (sortState2.order_by_qty === "ascending") {
    search_param3.order_by_qty = 1;
  } else if (sortState2.order_by_qty === "descending") {
    search_param3.order_by_qty = 2;
  } else {
    search_param3.order_by_qty = 0;
  }
  getList3({ cP: currentPage3.value, size: pageSize3.value, tips: true });
};

onMounted(() => {
  getCurrencyOption();
});
</script>

<style scoped>
.check {
  color: #666;
  font-size: 14px;
}
.flex {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.flexBox {
  flex: 1;
  display: flex;
  line-height: 30px;
  text-align: right;
  margin-right: 50px;
}
.flexTitle {
  width: 110px;
  text-align: center;
}
/*分页样式 */
.demo-pagination-block {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputStyle {
  margin-bottom: 10px;
  margin-left: 50px;
  line-height: 30px;
  display: flex;
  text-align: right;
  flex: 1;
}
.inputStyle .span1 {
  width: 320px;
  padding-right: 15px;
  line-height: 30px;
}
.inputStyle .span2 {
  width: 100%;
}

.inputStyle .span3 {
  flex: 1;
  margin-left: 20px;
  font-size: 13px;
}

:deep(.el-tabs__nav-wrap::after) {
  background-color: #fff !important;
}
.demo-tabs > .el-tabs__content {
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
:deep(.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner) {
  background-color: var(--el-checkbox-checked-bg-color);
  border-color: var(--el-checkbox-checked-input-border-color);
}
:deep(.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after) {
  border-color: #fff;
}
:deep(.el-checkbox__input.is-disabled + span.el-checkbox__label) {
  color: #606266;
}
</style>
